/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/webfonts.css"
import "./src/tailwind.css"
import "./src/global.css"

import React from "react"
//import browserHydrateFunction from './src/utils/browser-hydrate-function';

//import PreferencesProvider from "./src/context/preferences.ctx"
import NotificationProvider from "./src/context/notification.ctx"
import NovaTheme from "@ui-kit/wrapper"

// IE 11 Polyfilss
//require('es6-promise').polyfill();
//require('isomorphic-fetch');

export const wrapRootElement = ({ element }) => {
  return (
      <NotificationProvider>
        <NovaTheme>{element}</NovaTheme>
      </NotificationProvider>
  )
}

//export const replaceHydrateFunction = browserHydrateFunction;
